import cog from '../assets/cog.png';
import cylinder from '../assets/cylinder.png';
import noodle from '../assets/noodle.png';
import ArrowRight from '../assets/arrow-right.svg';
import { motion, useScroll, useTransform } from "motion/react"
import { useRef } from 'react';
import { Link } from 'react-scroll';

function Hero() {
    const heroRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: heroRef,
        offset: ["start end", "end start"],
    });
    const translateY = useTransform(scrollYProgress, [0, 1], [150, -150]);
    /*useMotionValueEvent (translateY, 'change', (latestValue) => {
        console.log(latestValue);
    });*/

    return (
        <section className='min-h-screen px-4 pt-20 pb-20 md:pt-15 md:pb-10 bg-[radial-gradient(ellipse_200%_100%_at_bottom_left,#183EC2,#EAEEFE_60%)] overflow-x-clip '>
            <div className='container'>
                <div className='md:flex items-center'>
                    <div className='md:w-[600px]'>
                        <div className='text-[#010D3E]'>⭐⭐⭐⭐⭐ +10 proyectos entregados <br />
                            Clientes satisfechos en múltiples industrias.</div>
                        <h1 className="h1 mt-4 md:text-7xl py-5"> Soluciones digitales que impulsan tu negocio</h1>
                        <p className='text-xl text-black tracking-tight mt-4'>
                            Transformo tus ideas en soluciones digitales que marcan la diferencia.
                            Desde páginas web impactantes hasta aplicaciones personalizadas que se adaptan a tus necesidades específicas,
                            cada solución está diseñada para impulsar tu crecimiento.
                        </p>
                        {/*<ul className="space-y-2 mt-4 text-black">
                            <li>✔ Soluciones personalizadas para cada negocio.</li>
                            <li>✔ Proceso ágil y colaborativo.</li>
                            <li>✔ Entrega garantizada en tiempos acordados.</li>
                            <li>✔ Soporte post-lanzamiento incluido.</li>
                        </ul>*/}
                        <div className='flex gap-2 item-center mt-[30px]'>
                            <button className="btn-primary font-bold py-3 px-6 text-xl"
                                onClick={() => window.open('https://wa.me/5492235972102?text=Hola%2C%20me%20gustaría%20saber%20más%20sobre%20tus%20servicios', '_blank')}
                            >
                                Contáctame para empezar
                            </button>
                            <button className="btn-borderless text-lg">
                                <Link to="services"  smooth={true} duration={500}  className="inline-flex items-center">
                                    Ver mis servicios
                                    <img src={ArrowRight} alt="Arrow Right" className="w-7 h-7 inline-block ml-1" />
                                </Link>
                            </button>
                        </div>
                    </div>
                    <div className='mt-20 md:mt-0 md:h-[648px] md:flex-1 relative'>
                        <motion.img
                            src={cog} alt='cog'
                            className='md:absolute md:h-full md:w-auto md:max-w-none md:-left-1 md:top-10'
                            animate={{
                                translateY: [-30, 30],
                            }}
                            transition={{
                                repeat: Infinity,
                                repeatType: 'mirror',
                                duration: 3,
                                ease: 'easeInOut',
                            }}
                        />
                        <motion.img
                            src={cylinder} alt="cylinder"
                            width={220} height={220}
                            className='hidden md:block -top-[120px] -left-240 md:absolute'
                            style={{
                                translateY: translateY,
                            }}
                        />
                        <motion.img
                            src={noodle} alt="noodle"
                            width={220} height={220}
                            className='hidden  lg:block absolute top-[524px] left-[448px] rotate-[30deg]'
                            style={{
                                rotate: 30,
                                translateY: translateY,
                            }}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Hero;