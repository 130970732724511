import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilRuler, faSyncAlt, faHeadset } from '@fortawesome/free-solid-svg-icons';


const WhyMe = () => {
    return (
        <section className="relative bg-[#183EC2] text-white overflow-hidden">
            {/* Curva superior */}
            <div className="absolute top-0 left-0 w-full h-20 overflow-hidden bg-white" style={{ clipPath: 'ellipse(60% 200% at 50% -100%)' }}></div>

            {/* Contenedor principal */}
            <div className="container mx-auto px-4 py-20 text-center">
                <h2 className="section-title py-2 my-10 text-white">
                    ¿Por qué trabajar conmigo?
                </h2>

                {/* Tarjetas */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-[1000px] mx-auto">
                    <div className="text-center">
                        <FontAwesomeIcon icon={faPencilRuler} className="text-blue-400 text-5xl mb-4 w-16 h-16" />
                        <p className="text-xl mt-4">
                            Cada proyecto es único, diseñado a la medida de tus necesidades y objetivos.
                        </p>
                    </div>

                    <div className="text-center">
                        <FontAwesomeIcon icon={faSyncAlt} className="text-blue-300 text-5xl mb-4 w-16 h-16" />
                        <p className="text-xl mt-4">
                            Estarás al tanto de cada paso del proceso, con actualizaciones constantes y transparentes.
                        </p>
                    </div>

                    <div className="text-center">
                        <FontAwesomeIcon icon={faHeadset} className="text-blue-200 text-5xl mb-4 w-16 h-16" />
                        <p className="text-xl mt-4">
                            Siempre disponible para resolver dudas, hacer ajustes y asegurar el éxito de tu proyecto.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhyMe;