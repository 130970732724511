import checkIcon from "../assets/check.svg";
import { twMerge } from "tailwind-merge";
import starImage from '../assets/tube.png';
import { motion, useScroll, useTransform } from 'framer-motion';
import { useRef } from 'react';

function Pricing() {
    const sectionRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: sectionRef,
        offset: ['start end', 'end start'],
    });

    const translateY = useTransform(scrollYProgress, [0, 1], [150, -150]);

    return (
        <section className="py-24" ref={sectionRef}>
            <div className="container">
                <div className="section-heading max-w-[640px] relative">
                    <h2 className="section-title py-2">Elige la solución perfecta para tu negocio</h2>
                    <p className="section-description mt-5">Precios claros y adaptados a tus necesidades. Encuentra el plan ideal para tu proyecto.</p>
                    <motion.img
                        src={starImage} alt="Star" width={260}
                        className='hidden lg:block absolute left-[-300px] top-[100px]'
                        style={{ translateY }}
                    />
                </div>
                <div className="flex flex-col gap-6 items-center mt-10 md:flex-row md:items-end justify-center">
                    {pricingTiers.map(({ title, monthlyPrice, buttonText, popular, inverse, features }) => (
                        <div
                            className={twMerge("card",
                                inverse === true && "border-black bg-black text-white")}
                        >
                            <div className="flex justify-between">
                                <h3
                                    className={twMerge("text-lg font-bold text-black/50",
                                        inverse === true && "text-white")}
                                >
                                    {title}
                                </h3>
                                {popular === true && (
                                    <div className="inline-flex text-sm px-4 py-1.5 rounded-xl border border-white/20 self-center">
                                        <motion.span
                                            className="bg-[linear-gradient(to_right,#DD7DDF,#E1CD86,#BBCB92,#71C2EF,#3BFFFF,#DD7DDF,#E1CD86,#BBCB92,#71C2EF,#3BFFFF)]
                                            [background-size:200%] text-transparent bg-clip-text font-medium"
                                            animate={{
                                                backgroundPositionX: "100%",
                                            }}
                                            transition={{
                                                repeat: Infinity,
                                                ease: "linear",
                                                repeatType: "loop",
                                                duration: 2,
                                            }}
                                        >
                                            Popular
                                        </motion.span>
                                    </div>
                                )}
                            </div>
                            <div className="flex items-baseline gap-1 mt-[30px]">
                                <span className="text-4xl font-bold tracking-tight leading-none">{monthlyPrice}</span>
                                {/*!inverse && (
                                    <span className="tracking-tight font-bold text-black/50">/month</span>
                                )*/}
                            </div>
                            <button
                                className={twMerge("btn-primary w-full mt-[30px] ",
                                    inverse === true && "bg-white text-black ")}
                                onClick={() => window.open('https://wa.me/5492235972102?text=Hola%2C%20me%20gustaría%20saber%20más%20sobre%20tus%20servicios', '_blank')}
                            >
                                {buttonText}
                            </button>
                            <ul className="flex flex-col gap-5 mt-8">
                                {features.map((feature) => (
                                    <li className="text-sm flex items-center gap-4">
                                        <img src={checkIcon} alt="Check"
                                            className={twMerge("h-6 w-6",
                                                inverse === true && "invert")}
                                        />
                                        <span>{feature}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )
                    )}

                </div>

            </div>
        </section>
    );
}

export default Pricing;


const pricingTiers = [
    {
        title: "E-commerce Personalizado (Suscripcion)",
        monthlyPrice: "Desde $89/mes",
        buttonText: "Solicitar presupuesto",
        popular: false,
        inverse: false,
        features: [
            "Diseño atractivo y fácil de usar",
            "Gestión de productos e inventario",
            "Integración con pasarelas de pago seguras",
            "Optimización para dispositivos móviles",
            "Soporte técnico y mantenimiento continuo",
        ],
    },
    {
        title: "Paginas Web & Landing Pages",
        monthlyPrice: "Desde $349",
        buttonText: "Solicitar presupuesto",
        popular: true,
        inverse: true,
        features: [
            "Diseño único y profesional que refleja la identidad de tu marca",
            "Optimizado para SEO y tiempos de carga rápidos para atraer más clientes",
            "Adaptado a dispositivos móviles para garantizar una experiencia fluida",
            "Redirección directa a WhatsApp para facilitar la comunicación instantánea",
            "Contenido estático optimizado para mantener rendimiento y estabilidad",
            "Soporte técnico incluido para resolver cualquier problema rápidamente",
            "Entrega rápida y garantizada para cumplir con tus plazos",
        ],
        
    },
    {
        title: "Aplicaciones Web Personalizadas",
        monthlyPrice: "Pago único o Suscripción",	
        buttonText: "Consultame por tu App",
        popular: false,
        inverse: false,
        features: [
            "Diseño intuitivo y adaptado al usuario",
            "Desarrollo escalable para soportar crecimiento",
            "Integración con APIs externas",
            "Autenticación segura y manejo de roles",
            "Optimización para rendimiento y velocidad",
            "Compatibilidad con múltiples dispositivos y navegadores",
            "Base de datos robusta y eficiente",
            "Soporte técnico post-lanzamiento",
            "Automatización de procesos clave del negocio",
            "Personalización completa según tus necesidades",
        ],
    },
];