import springImage from '../assets/pyramid.png';
import { motion, useScroll, useTransform } from 'framer-motion';
import { useRef } from 'react';

const Services = () => {

    const sectionRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: sectionRef,
        offset: ['start end', 'end start'],
    });

    const translateY = useTransform(scrollYProgress, [0, 1], [-150, 150]);

    return (
        <section className="bg-white text-gray-800 py-20 overflow-x-clip"
            ref={sectionRef}
        >
            <div className="flex justify-center">
                    <div className="border border-gray-300 rounded-3xl px-2 py-1 shadow-2xl ">Servicios</div>
                </div>
            <div className="container text-center ">
                <div className="section-heading max-w-[620px] relative">
                    <h2 className="section-title py-2 mb-10">
                        ¿Que puedo hacer por vos?
                    </h2>
                    <p className="section-description text-xl mb-10 text-black">
                        Cada servicio está pensado para simplificar procesos y hacer crecer tu negocio.
                    </p>
                    <motion.img
                        src={springImage} alt="Spring" width={260}
                        className='absolute -right-[440px] top-[400px] '
                        style={{ translateY }}
                    />
                </div>

                {/* Tarjetas de servicios */}
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-8 max-w-[1200px] mx-auto">
                    <div className="p-6 border rounded-lg shadow-md hover:shadow-lg transition">
                        <h3 className="text-2xl font-bold mb-4">Tu Página, Tu Identidad</h3>
                        <p>Diseño y desarrollo de sitios web únicos y funcionales que representan lo mejor de tu marca. Desde landing pages que convierten hasta portales completos, todo a medida.</p>
                    </div>
                    <div className="p-6 border rounded-lg shadow-md hover:shadow-lg transition">
                        <h3 className="text-2xl font-bold mb-4">Cobros Sin Complicaciones</h3>
                        <p>Integra sistemas de pago confiables como MercadoPago o Paypal para que tus clientes puedan pagar rápido y seguro, sin importar dónde estén.</p>
                    </div>
                    <div className="p-6 border rounded-lg shadow-md hover:shadow-lg transition">
                        <h3 className="text-2xl font-bold mb-4">Procesos que Trabajan por Ti</h3>
                        <p>Automatiza tareas repetitivas y optimiza el flujo de tu negocio con soluciones que ahorran tiempo y esfuerzo, permitiéndote enfocarte en lo que importa.</p>
                    </div>
                    <div className="p-6 border rounded-lg shadow-md hover:shadow-lg transition">
                        <h3 className="text-2xl font-bold mb-4">Conexiones Inteligentes</h3>
                        <p>Construcción de APIs robustas para conectar servicios, integrar plataformas y escalar tu negocio sin límites.</p>
                    </div>
                </div>
                {/*<motion.img
                        src={starImage} alt="Star" width={260}
                        className='absolute left-[0px] top-[950px]'
                        style={{ translateY }}
                    />*/}                
            </div>
        </section>
    );
};

export default Services;
