import acmeLogo from '../assets/logo-acme.png';
import quantumLogo from '../assets/logo-quantum.png';
import echoLogo from '../assets/logo-echo.png';
import celestialLogo from '../assets/logo-celestial.png';
import pulseLogo from '../assets/logo-pulse.png';
import apexLogo from '../assets/logo-apex.png';
import { motion } from 'framer-motion';

function LogoTicker() {
    return (
        <div className='py-8 md:py-12 bg-white'>
            <div className="container">
                <div className='flex overflow-hidden [mask-image:linear-gradient(to_right,transparent,black,transparent)]'>
                    <motion.div 
                        className='flex gap-14 flex-none pr-14'
                        animate={{
                            translateX: "-50%",
                        }}
                        transition={{
                            repeat: Infinity,
                            duration: 20,
                            ease: 'linear',  
                            repeatType: 'loop',                          
                        }}
                    >
                        <img src={acmeLogo} alt="acme" className='logo-ticker-image' />
                        <img src={quantumLogo} alt="quantum" className='logo-ticker-image' />
                        <img src={echoLogo} alt="echo" className='logo-ticker-image' />
                        <img src={celestialLogo} alt="celestial" className='logo-ticker-image' />
                        <img src={pulseLogo} alt="pulse" className='logo-ticker-image' />
                        <img src={apexLogo} alt="apex" className='logo-ticker-image' />

                        {/* Second set of logos 4 animation */}
                        <img src={acmeLogo} alt="acme" className='logo-ticker-image' />
                        <img src={quantumLogo} alt="quantum" className='logo-ticker-image' />
                        <img src={echoLogo} alt="echo" className='logo-ticker-image' />
                        <img src={celestialLogo} alt="celestial" className='logo-ticker-image' />
                        <img src={pulseLogo} alt="pulse" className='logo-ticker-image' />
                        <img src={apexLogo} alt="apex" className='logo-ticker-image' />

                    </motion.div>
                </div>
            </div>
        </div>
    );
}

export default LogoTicker;