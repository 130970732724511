import Header from './components/Header';
import Hero from './components/Hero';
import LogoTicker from './components/LogoTicker';
import CallToAction from './components/CallToAction';
import Footer from './components/Footer';
import WhyMe from './components/WhyMe';
import Services from './components/Services';
import Pricing from './components/Pricing';
import Testimonios from './components/testimonios';


function App() {
  return (
    <>
      <Header />
      <div id="hero">
        <Hero /> 
      </div>  
      <LogoTicker /> 
      <div id="services">
        <Services />
      </div>
      <div id="pricing">
        <Pricing />
      </div>   
      <Testimonios />       
      <div id="whyMe">
        <WhyMe />
      </div>    
      <CallToAction />
      <Footer />
    </>
  );
}

export default App;