import avatar1 from "../assets/avatar-1.png";
import avatar2 from "../assets/avatar-2.png";
import avatar3 from "../assets/avatar-3.png";
import avatar4 from "../assets/avatar-4.png";
import avatar5 from "../assets/avatar-5.png";
import avatar6 from "../assets/avatar-6.png";
import avatar7 from "../assets/avatar-7.png";
import avatar8 from "../assets/avatar-8.png";
import avatar9 from "../assets/avatar-9.png";
import { motion } from "framer-motion";
const testimonials = [  
    {
        text: "Como diseñadora, trabajar con landing pages personalizadas ha sido un gran avance para mis proyectos.",
        imageSrc: avatar1,
        name: "Lucía Fernández",
        username: "@luciafdez",
    },
    {
        text: "Desde que implementamos nuestro e-commerce, las ventas crecieron más de lo que imaginábamos.",
        imageSrc: avatar2,
        name: "Tomás Álvarez",
        username: "@tomi_alvz",
    },
    {
        text: "La app web personalizada que desarrollaron me ayudó a organizar mejor mi negocio y mis tiempos.",
        imageSrc: avatar3,
        name: "Martina Pérez",
        username: "@martinapz",
    },
    {
        text: "Me sorprendió lo rápido y fácil que fue lanzar nuestra landing page con ayuda de este equipo.",
        imageSrc: avatar4,
        name: "José Morales",
        username: "@josemorales",
    },
    {
        text: "Implementar un e-commerce bien diseñado fue clave para mejorar nuestra experiencia de cliente.",
        imageSrc: avatar6,
        name: "Camila López",
        username: "@camilalopez",
    },
    {
        text: "El desarrollo de nuestra app web incluyó integraciones clave que nos simplificaron la gestión.",
        imageSrc: avatar5,
        name: "Diego Ramírez",
        username: "@diegoramirez",
    },
    {
        text: "Con su ayuda, logramos que la gestión de proyectos y comunicación fueran mucho más eficientes.",
        imageSrc: avatar7,
        name: "Anil Kumar",
        username: "@Anilkumar",
    },
    {
        text: "Ahora podemos administrar pedidos y clientes desde nuestra aplicación personalizada, todo en un lugar.",
        imageSrc: avatar9,
        name: "Santiago Rodríguez",
        username: "@santirodriguez",
    },
    {
        text: "Nuestra nueva landing page es atractiva, funcional y optimizada para atraer más clientes.",
        imageSrc: avatar8,
        name: "María Belén Castro",
        username: "@mbcastro",
    },
];

const firstColumn = testimonials.slice(0, 3);
const secondColumn = testimonials.slice(3, 6);
const thirdColumn = testimonials.slice(6, 9);

function Testimonios() {
    return (
        <section className="bg-white">
            <div className="container">
                <div className="flex justify-center">
                    <div className="border border-gray-300 rounded-3xl px-2 py-1 shadow-2xl ">Testimonios</div>
                </div>
                <h2 className="section-title mt-5">Lo que nuestros clientes dicen</h2>
                <p className="section-description mt-5">Conoce las experiencias de nuestros clientes al utilizar nuestra plataforma.</p>
                
                <div className=" relative h-[650px] overflow-hidden flex justify-center gap-6 mt-10 [mask-image:linear-gradient(to_bottom,transparent,black_25%,black_75%,transparent)]">

                    {/* Columna 1 */}
                    <motion.div 
                        animate={{ translateY: "-120%"}}
                        transition={{
                            duration: 15,
                            repeat: Infinity,
                            ease: "linear",
                            repeatType: "loop",
                        }}
                        className="flex flex-col gap-6 "
                    >
                        {firstColumn.concat(firstColumn).map(({ text, imageSrc, name, username }) => (
                            <div className="card">
                                <div>{text}</div>
                                <div className="flex items-center gap-2 mt-5">
                                    <img src={imageSrc} alt={name} width={40} height={40} className="h-10 w-10 rounded-full" />
                                    <div className="flex flex-col">
                                        <div className="font-medium tracking-tight leading-5">{name}</div>
                                        <div className="leading-5 tracking-tight">{username}</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                              
                    </motion.div>   

                    {/* Columna 2 */}                
                    <motion.div 
                        animate={{ translateY: "-113.5%"}}
                        transition={{
                            duration: 19,
                            repeat: Infinity,
                            ease: "linear",
                            repeatType: "loop",
                        }}
                        className="hidden md:flex flex-col gap-6 "
                    >
                        {secondColumn.concat(secondColumn).map(({ text, imageSrc, name, username }) => (
                            <div className="card">
                                <div>{text}</div>
                                <div className="flex items-center gap-2 mt-5">
                                    <img src={imageSrc} alt={name} width={40} height={40} className="h-10 w-10 rounded-full" />
                                    <div className="flex flex-col">
                                        <div className="font-medium tracking-tight leading-5">{name}</div>
                                        <div className="leading-5 tracking-tight">{username}</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </motion.div>

                    {/* Columna 3 */}                
                    <motion.div 
                        animate={{ translateY: "-117%"}}
                        transition={{
                            duration: 17,
                            repeat: Infinity,
                            ease: "linear",
                            repeatType: "loop",
                        }}
                        className="hidden lg:flex flex-col gap-6 "
                    >
                        {thirdColumn.concat(thirdColumn).map(({ text, imageSrc, name, username }) => (
                            <div className="card">
                                <div>{text}</div>
                                <div className="flex items-center gap-2 mt-5">
                                    <img src={imageSrc} alt={name} width={40} height={40} className="h-10 w-10 rounded-full" />
                                    <div className="flex flex-col">
                                        <div className="font-medium tracking-tight leading-5">{name}</div>
                                        <div className="leading-5 tracking-tight">{username}</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </motion.div>

                </div>
            
            </div>
        </section>
    );
}

export default Testimonios;



