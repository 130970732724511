import ArrowRight from '../assets/arrow-right.svg';
import starImage from '../assets/star.png';
import springImage from '../assets/spring.png';
import { motion, useScroll, useTransform } from 'framer-motion';
import { useRef } from 'react';
import { Link } from 'react-scroll';

function CallToAction() {
    const sectionRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: sectionRef,
        offset: ['start end', 'end start'],
    });

    const translateY1 = useTransform(scrollYProgress, [0, 1], [-150, 150]);
    const translateY2 = useTransform(scrollYProgress, [0, 1], [150, -150]);


    return (
        <section ref={sectionRef} className='bg-gradient-to-b from-white to-[#d2dcff] py-24 overflow-x-clip'>
            <div className="container">
                <div className='section-heading max-w-[600px] relative'>
                    <h2 className="section-title py-2">¿Listo para llevar tu negocio al siguiente nivel?</h2>
                    <p className="section-description mt-5">
                        Hablemos sobre cómo puedo ayudarte a crear la solución digital que necesitas. Tu idea merece ser realidad.
                    </p>
                    <motion.img
                        src={starImage} alt="Star" width={360}
                        className='absolute -left-[450px] -top-[100px]'
                        style={{ translateY: translateY1 }}
                    />
                    <motion.img
                        src={springImage} alt="Spring" width={360}
                        className='absolute -right-[381px] -top-[19px]'
                        style={{ translateY: translateY2 }}
                    />
                </div>
                <div className="flex gap-2 justify-center mt-10">
                    <button className="btn-primary font-bold py-3 px-6 text-xl"
                        onClick={() => window.open('https://wa.me/5492235972102?text=Hola%2C%20me%20gustaría%20saber%20más%20sobre%20tus%20servicios', '_blank')}
                    >
                        Contáctame para empezar
                    </button>
                    <button className="btn-borderless text-lg">
                        <Link to="services" smooth={true} duration={500} className="inline-flex items-center">
                            Ver mis servicios
                            <img src={ArrowRight} alt="Arrow Right" className="w-7 h-7 inline-block ml-1" />
                        </Link>
                    </button>
                </div>
            </div>
        </section>
    );
}

export default CallToAction;