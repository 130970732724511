import { useState } from 'react';
import { Link } from 'react-scroll';
import menuIcon from '../assets/menu.svg';
import logo from '../assets/logo-sin-fondo2.png';

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header className="fixed top-0 left-0 w-full backdrop-blur-sm bg-white/30 shadow-sm z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          {/* Logo */}
          <div className="flex-shrink-0">
          <Link to="hero" smooth={true} duration={500} className="cursor-pointer text-xl font-bold"><img src={logo} alt='logo' className='w-auto h-[45px]' /></Link>
          </div>
          {/* Menú */}
          <nav className="hidden md:flex space-x-8 font-medium">
            <Link to="services" smooth={true} duration={500}
              className="cursor-pointer text-gray-800 hover:text-gray-400"
            >
              Servicios
            </Link>
            <Link to="pricing" smooth={true} duration={500}
              className="cursor-pointer text-gray-800 hover:text-gray-400"
            >
              Precios
            </Link>
            <Link to="whyMe" smooth={true} duration={500}
              className="cursor-pointer text-gray-800 hover:text-gray-400"
            >
              Trabajar conmigo
            </Link>
            <button className="btn-primary"
              onClick={() => window.open('https://wa.me/5492235972102?text=Hola%2C%20me%20gustaría%20saber%20más%20sobre%20tus%20servicios', '_blank')}
            >
              Contáctame
            </button>
          </nav>
          {/* Botón Hamburguesa */}
          <div className="md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="text-gray-800 focus:outline-none"
            >
              <img src={menuIcon} alt="Menu" className="h-8 w-8" />
            </button>
          </div>
        </div>
      </div>
      {/* Menú Hamburguesa */}
      {isOpen && (
        <div className="md:hidden bg-white/80 backdrop-blur-lg shadow-md font-medium">
          <Link to="services" smooth={true} duration={500}
            className="block px-4 py-2 text-gray-800 hover:text-gray-400"
            onClick={() => setIsOpen(false)} // Cerrar el menú al hacer clic
          >
            Servicios
          </Link>
          <Link to="pricing" smooth={true} duration={500}
            className="block px-4 py-2 text-gray-800 hover:text-gray-400"
            onClick={() => setIsOpen(false)}
          >
            Precios
          </Link>
          <Link to="whyMe" smooth={true} duration={500}
            className="block px-4 py-2 text-gray-800 hover:text-gray-400"
            onClick={() => setIsOpen(false)}
          >
            Trabajar conmigo
          </Link>
          <button className="btn-primary w-auto my-2 mx-2"
            onClick={() => window.open('https://wa.me/5492235972102?text=Hola%2C%20me%20gustaría%20saber%20más%20sobre%20tus%20servicios', '_blank')}
          >
            Contáctame
          </button>
        </div>
      )}
    </header>
  );
}

export default Header;