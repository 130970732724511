import { Link } from 'react-scroll';
/*import socialX from '../assets/social-x.svg';
import socialInsta from '../assets/social-insta.svg';
import socialLinkedIn from '../assets/social-linkedin.svg';
import socialPin from '../assets/social-pin.svg';
import socialYoutube from '../assets/social-youtube.svg';*/
import logo from '../assets/logo-sin-fondo2.png';

function Footer() {
    return (
        <footer className='bg-black text-[#BCBCBC] text-sm py-10 text-center'>
            <div className="container">
                <div className="inline-flex relative before:content-[''] before:top-1 before:bottom-0  before:w-full before:blur before:bg-[linear-gradient(to_right,#F87BFF,#FB92CF,#FFDD9B,#C2F0B1,#2FD8FE)] before:absolute">
                    <Link to="hero" smooth={true} duration={500} className="cursor-pointer relative text-xl font-bold text-white">
                        <img src={logo} alt='logo' className='w-auto h-[45px]'/>
                    </Link>
                </div>
                <nav className='flex flex-col gap-6 mt-6 md:flex-row md:justify-center'>
                    <Link to="services" smooth={true} duration={500} className='cursor-pointer' >Servicios</Link>
                    <Link to="services" smooth={true} duration={500} className='cursor-pointer' >Precios</Link>
                    <Link to="services" smooth={true} duration={500} className='cursor-pointer' >Trabajar conmigo</Link>                    
                </nav>
                {/*<div className='flex justify-center gap-6 mt-6'>
                    <img src={socialX} alt="Social X" className="invert" />
                    <img src={socialInsta} alt="Social Insta" className="invert" />
                    <img src={socialLinkedIn} alt="Social LinkedIn" className="invert" />
                    <img src={socialPin} alt="Social Pin" className="invert" />
                    <img src={socialYoutube} alt="Social Youtube" className="invert" />
                </div>*/}
                <p className='mt-6 '>&copy; 2025 mpanasci. All rights reserved.</p>
            </div>
        </footer>
    );
}

export default Footer;